import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import * as validate from '../../../../../../../../../../../core/utils/helpers/national-id.helper';
import { useTranslation } from 'react-i18next';
import { BsTelephone, BsPinMap, BsHouse, BsPerson } from 'react-icons/bs';
import { useFormik } from 'formik';
import Heading2 from '../../../../../../../../../../../components/text/Heading2';
import ContentCard from '../../../../../../../../../../../components/content/ContentCard';
import { FormInput, FormDob, SelectOption, FormSelect } from '@omnigenbiodata/ui';
import {
  TELEPHONE_NUMBER_REGEX,
  POSTAL_CODE_REGEX,
} from '../../../../../../../../../../../core/constants/forms.constant';
import { ParticipantProfile } from '../../../../../../../../../../../core/types/participants.types';

export interface ProfileFormProps {
  profile: ParticipantProfile;
  onSubmit: (values: any) => void;
}

function ProfileForm({ profile, onSubmit }: ProfileFormProps) {
  const { t } = useTranslation('portal');

  const validationSchema = yup.object({
    address: yup.string().test(
      'oneOfRequired',
      t('addressIdentifierRequired', {
        ns: 'validation',
      }),
      function () {
        return this.parent.postcode || this.parent.address;
      },
    ),
    dob: yup.string().required(),
    firstName: yup.string().required(
      t('firstNameRequired', {
        ns: 'validation',
      }),
    ),
    landline: yup.string().matches(TELEPHONE_NUMBER_REGEX, t('landlineTelephoneFormat', { ns: 'validation' })),
    nationalID: yup.string().test('NationalID', t('nationalIDFormat', { ns: 'validation' }), function (value) {
      return value ? validate.nationalID(value, this.parent.dob, this.parent.sex) : true;
    }),
    postcode: yup
      .string()
      .test(
        'oneOfRequired',
        t('addressIdentifierRequired', {
          ns: 'validation',
        }),
        function () {
          return this.parent.postcode || this.parent.address;
        },
      )
      .matches(POSTAL_CODE_REGEX, t('postalCodeFormat', { ns: 'validation' })),
    sex: yup.string().required(),
    surname: yup.string().required(
      t('lastNameRequired', {
        ns: 'validation',
      }),
    ),
  });

  const formik = useFormik({
    initialValues: {
      address: (profile?.address as string) || '',
      dob: (profile?.dob as string) || '',
      firstName: (profile?.firstName as string) || '',
      id: (profile?.id as string) || '',
      landline: (profile?.landline as string) || '',
      nationalID: (profile?.nationalID as string) || '',
      postcode: (profile?.postcode as string) || '',
      sex: (profile?.sex as string) || '',
      surname: (profile?.surname as string) || '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      onSubmit({
        ...values,
        nationalID: values.nationalID || null,
        participantStatus: profile.participantStatus,
      });
    },
  });

  return (
    <ContentCard>
      <form onSubmit={formik.handleSubmit}>
        <Heading2 color="textPrimary" icon={BsPerson}>
          {t('participantProfile.title')}
        </Heading2>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormInput
              error={formik.errors.firstName as string}
              name="firstName"
              label={t('firstName', { ns: 'forms' })}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.firstName ? true : false}
              value={formik.values.firstName}
              startAdornment={<BsPerson fontSize={20} style={{ marginRight: 10 }} />}
              disabled={profile.deceased || profile.dataHasArrived ? true : false}
            />
            <FormInput
              error={formik.errors.surname as string}
              name="surname"
              label={t('surname', { ns: 'forms' })}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.surname ? true : false}
              value={formik.values.surname}
              startAdornment={<BsPerson fontSize={20} style={{ marginRight: 10 }} />}
              disabled={profile.deceased || profile.dataHasArrived ? true : false}
            />
            <FormSelect
              placeholder={''}
              error={formik.errors.sex as string}
              name="sex"
              label={t('sexAtBirth.label', { ns: 'forms' })}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.sex ? true : false}
              value={formik.values.sex}
              options={
                t('sexAtBirth.options', {
                  ns: 'forms',
                  returnObjects: true,
                }) as [SelectOption]
              }
              disabled={profile.deceased || profile.dataHasArrived ? true : false}
            />
            <FormDob
              error={formik.errors.dob as string}
              name="dob"
              label={t('dob', { ns: 'forms' })}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              setValue={formik.setFieldValue}
              touched={formik.touched.dob ? true : false}
              value={formik.values.dob}
              minAge={18}
              disabled={profile.deceased || profile.dataHasArrived ? true : false}
            />
            <FormInput
              error={formik.errors.nationalID as string}
              name="nationalID"
              label={t('nationalID', { ns: 'forms' })}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.nationalID ? true : false}
              value={formik.values.nationalID}
              startAdornment={
                <>
                  <BsPinMap fontSize={20} style={{ marginRight: 10 }} />
                </>
              }
              disabled={profile.deceased || profile.dataHasArrived ? true : false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              error={formik.errors.landline as string}
              name="landline"
              label={t('landline', { ns: 'forms' })}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.landline ? true : false}
              value={formik.values.landline}
              startAdornment={
                <>
                  <BsTelephone fontSize={20} style={{ marginRight: 10 }} />
                </>
              }
              disabled={profile.deceased || profile.dataHasArrived ? true : false}
            />

            <FormInput
              error={formik.errors.address as string}
              name="address"
              label={t('address', { ns: 'forms' })}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.address ? true : false}
              value={formik.values.address}
              startAdornment={
                <>
                  <BsHouse fontSize={20} style={{ marginRight: 10 }} />
                </>
              }
              disabled={profile.deceased || profile.dataHasArrived ? true : false}
            />
            <FormInput
              error={formik.errors.postcode as string}
              name="postcode"
              label={t('postcode', { ns: 'forms' })}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.postcode ? true : false}
              value={formik.values.postcode}
              startAdornment={
                <>
                  <BsPinMap fontSize={20} style={{ marginRight: 10 }} />
                </>
              }
              disabled={profile.deceased || profile.dataHasArrived ? true : false}
            />
          </Grid>
        </Grid>

        {!profile.deceased && (
          <Box mt={4}>
            <Button variant="contained" color="primary" size="large" type="submit">
              {t('participantProfile.button')}
            </Button>
          </Box>
        )}
      </form>
    </ContentCard>
  );
}

export default ProfileForm;
