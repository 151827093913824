import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Authentication from './Authentication';
import Login from './Authentication/scenes/Login';
import Logout from './Authentication/scenes/Logout';
import ResetPassword from './Authentication/scenes/ResetPassword';
import SubmitResetPassword from './Authentication/scenes/SubmitResetPassword';

import PortalScene from './Portal';
import HomeScene from './Portal/scenes/Home';
import MonitoringScene from './Portal/scenes/Monitoring';

import ParticipantsScene from './Portal/scenes/Participants';
import ParticipantLookup from './Portal/scenes/Participants/scenes/ParticipantLookup';
import ParticipantResult from './Portal/scenes/Participants/scenes/ParticipantResult';
import ParticipantDetail from './Portal/scenes/Participants/scenes/ParticipantResult/scenes/ParticipantDetails';
import ParticipantWithdrawal from './Portal/scenes/Participants/scenes/ParticipantResult/scenes/ParticipantWithdrawal';
import WithdrawalOption from './Portal/scenes/Participants/scenes/ParticipantResult/scenes/ParticipantWithdrawal/scenes/WithdrawalOption';
import WithdrawalReason from './Portal/scenes/Participants/scenes/ParticipantResult/scenes/ParticipantWithdrawal/scenes/WithdrawalReason';
import WithdrawalConfirm from './Portal/scenes/Participants/scenes/ParticipantResult/scenes/ParticipantWithdrawal/scenes/WithdrawalConfirm';

import ConsentScene from './Consent';
import TermsScene from './Consent/scenes/Terms';
import PrivacyScene from './Consent/scenes/Privacy';

import SplashScene from './Splash';

import ROUTES from '../core/constants/routes.constant';
import { AuthRoute } from '@omnigenbiodata/ui';
import UpdateStatusConfirmScene from './Portal/scenes/Participants/scenes/ParticipantResult/scenes/ParticipantUpdateStatus/scenes/UpdateStatusConfirm';

function Scenes() {
  return (
    <Routes>
      <Route path={ROUTES.auth} element={<Authentication />}>
        <Route path={ROUTES.authResetPassword} element={<ResetPassword />} />
        <Route path={ROUTES.authSubmitResetPassword} element={<SubmitResetPassword />} />
        <Route path={ROUTES.authSignOut} element={<Logout />} />
        <Route path={ROUTES.auth} element={<Login />} />
      </Route>
      <Route path={ROUTES.portal} element={<AuthRoute path={ROUTES.portal} element={PortalScene} />}>
        <Route path={ROUTES.portal} element={<HomeScene />} />
        <Route path={ROUTES.participantLookup} element={<ParticipantsScene />}>
          <Route path={ROUTES.participantLookup} element={<ParticipantLookup />} />
          <Route path={ROUTES.participant} element={<ParticipantResult />}>
            <Route path={ROUTES.participant} element={<ParticipantDetail />} />
            <Route path={ROUTES.participantWithdrawal} element={<ParticipantWithdrawal />}>
              <Route path={ROUTES.participantWithdrawal} element={<WithdrawalOption />} />
              <Route path={ROUTES.participantWithdrawalReason} element={<WithdrawalReason />} />
              <Route path={ROUTES.participantWithdrawalConfirm} element={<WithdrawalConfirm />} />
            </Route>
            <Route path={ROUTES.updateParticipantStatus} element={<UpdateStatusConfirmScene />} />
          </Route>
        </Route>
        <Route path={ROUTES.monitoring} element={<MonitoringScene />} />
      </Route>
      <Route path={ROUTES.splash} element={<AuthRoute path={ROUTES.splash} element={SplashScene} />} />
      <Route path={ROUTES.consentTerms} element={<AuthRoute path={ROUTES.consentTerms} element={ConsentScene} />}>
        <Route path={ROUTES.consentPrivacy} element={<PrivacyScene />} />
        <Route path={ROUTES.consentTerms} element={<TermsScene />} />
      </Route>
      <Route path="*" element={<Navigate to={ROUTES.portal} replace />} />
    </Routes>
  );
}

export default Scenes;
