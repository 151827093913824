import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import SearchIcon from '@mui/icons-material/Search';
import GroupIcon from '@mui/icons-material/Group';
import { ButtonRow, FormInput, FormSelect, Loader } from '@omnigenbiodata/ui';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import ROUTES, { URL_PARAMS } from '../../../../../../core/constants/routes.constant';
import InnerLayout from '../../../../../../layout/Inner';
import Heading1 from '../../../../../../components/text/Heading1';
import Heading2 from '../../../../../../components/text/Heading2';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import {
  getParticipantByHospitalNoThunk,
  resetParticipantResult,
  isBusySelector,
  hasLookupErrorSelector,
  participantProfileSelector,
  lookupErrorTypeSelector,
  formValueSelector,
  getParticipantStatusesThunk,
  participantStatusListSelector,
  getListParticipantsByParticipantStatusThunk,
  listParticipantsByParticipantStatusSelector,
} from '../../../../../../store/participant';
import { LookupErrorTypes } from '../../../../../../core/types/common.types';
import { Button } from '@mui/material';

function ParticipantsLookupScene() {
  const apiRef = useGridApiRef();
  const { t } = useTranslation('portal');
  const PAGE_SIZE = 50;
  const dispatch = useAppDispatch();
  const isBusy = useAppSelector(isBusySelector);
  const profile = useAppSelector(participantProfileSelector);
  const hasLookupError = useAppSelector(hasLookupErrorSelector);
  const lookupErrorType = useAppSelector(lookupErrorTypeSelector);
  const lookupValues = useAppSelector(formValueSelector);
  const [participantStatus, setParticipantStatus] = useState('');
  const participantStatusList = useAppSelector(participantStatusListSelector) || [];
  const listParticipantsByParticipantStatus = useAppSelector(listParticipantsByParticipantStatusSelector) || {
    participants: [],
    paginationToken: null,
  };
  const [paginationModel, setPaginationModel] = useState({
    page: 0, // Initial page number
    pageSize: PAGE_SIZE, // Default page size
  });

  const [rowCountState, setRowCountState] = useState(PAGE_SIZE);
  const handlePaginationChange = (newPaginationModel: any) => {
    if (newPaginationModel.page < paginationModel.page) {
      setPaginationModel(newPaginationModel);
      return;
    }

    setPaginationModel(newPaginationModel);
    dispatch(
      getListParticipantsByParticipantStatusThunk({
        status: participantStatus,
        paginationToken: listParticipantsByParticipantStatus.paginationToken,
      }),
    );
  };

  useEffect(() => {
    dispatch(resetParticipantResult());
    dispatch(getParticipantStatusesThunk({ status: 'ACTIVE' }));
  }, [dispatch]);

  useEffect(() => {
    if (listParticipantsByParticipantStatus && listParticipantsByParticipantStatus.participants) {
      if (listParticipantsByParticipantStatus.paginationToken !== null) {
        setRowCountState(rowCountState + PAGE_SIZE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listParticipantsByParticipantStatus]);

  const validationSchema = yup.object({
    hospitalNo: yup.lazy((value: string) =>
      value && value.charAt(0).toUpperCase() === 'D'
        ? yup
            .string()
            .required(
              t('hospitalNumRequired', {
                ns: 'validation',
              }),
            )
            .matches(/^DMSA[0-9]{8}-\w{2}$/, t('participantIdFormatError', { ns: 'validation' }))
        : yup
            .string()
            .required(
              t('hospitalNumRequired', {
                ns: 'validation',
              }),
            )
            .matches(
              /^[0-9]+$/,
              t('hospitalNoFormatError', {
                ns: 'validation',
              }),
            )
            .min(
              8,
              t('hospitalNoLengthError', {
                ns: 'validation',
              }),
            )
            .max(
              8,
              t('hospitalNoLengthError', {
                ns: 'validation',
              }),
            ),
    ),

    participantStatus: yup.string(),
  });

  const columns = [
    { field: 'id', headerName: 'Participant ID', flex: 2 },
    { field: 'hospitalNo', headerName: 'IALCH ID', flex: 2 },
    { field: 'firstName', headerName: 'First Name', flex: 2 },
    { field: 'surname', headerName: 'Surname', flex: 2 },
    {
      field: 'participantStatus',
      headerName: 'Status',
      flex: 2,
    },
  ];

  const formik = useFormik({
    initialValues: {
      hospitalNo: '',
      participantStatus: '',
    },
    validationSchema,
    onSubmit: ({ hospitalNo }) => {
      dispatch(getParticipantByHospitalNoThunk({ hospitalNo }));
    },
  });

  const statusListFormik = useFormik({
    initialValues: {},
    onSubmit: () => {
      dispatch(resetParticipantResult());
      dispatch(getListParticipantsByParticipantStatusThunk({ status: participantStatus, paginationToken: null }));
    },
  });

  if (profile && formik.values.hospitalNo) {
    return <Navigate to={ROUTES.participant.replace(URL_PARAMS.hospitalNo, profile.hospitalNo)} />;
  }

  return (
    <>
      <InnerLayout title="Site" title2="Portal">
        <Heading1 icon={GroupIcon}>{t('participantLookup.title')}</Heading1>

        <Paper elevation={9}>
          <Box p={6}>
            <Heading2 align="center">{t('participantLookup.subtitle')}</Heading2>
            <Typography paragraph variant="body1" align="center">
              {t('participantLookup.para1')}
            </Typography>
            <Box mb={2}>
              {hasLookupError && lookupErrorType.includes(LookupErrorTypes.NOT_FOUND) && (
                <Box p={4}>
                  <Alert color="error">
                    {t('participantLookup.errors.RecordNotFoundError', {
                      hospitalNo: lookupValues?.hospitalNo,
                    })}
                  </Alert>
                </Box>
              )}
              {hasLookupError && !lookupErrorType.includes(LookupErrorTypes.NOT_FOUND) && (
                <Box p={2}>
                  <Alert color="error">{t('participantLookup.errors.UnexpectedError')}</Alert>
                </Box>
              )}
              <Grid container spacing={2} mb={2}>
                <Grid item xs={6} md={6}>
                  <Paper elevation={2}>
                    <form onSubmit={formik.handleSubmit}>
                      <Box p={4}>
                        <FormInput
                          error={formik.errors.hospitalNo}
                          name="hospitalNo"
                          label={t('participantLookup.label')}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          touched={formik.touched.hospitalNo}
                          value={formik.values.hospitalNo}
                        />
                        <ButtonRow
                          showBack={false}
                          forwardColor="primary"
                          justifyContent="center"
                          forwardLabel={t('participantLookup.button')}
                          buttonSize="large"
                          forwardIcon={<SearchIcon style={{ fontSize: 30 }} />}
                        />
                      </Box>
                    </form>
                  </Paper>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Paper elevation={2}>
                    <form onSubmit={statusListFormik.handleSubmit}>
                      <Box p={4}>
                        <FormSelect
                          placeholder=""
                          name="participantStatuses"
                          label={'Status'}
                          onChange={(e: any) => {
                            setParticipantStatus(e.currentTarget.value);
                          }}
                          onBlur={() => {}}
                          value={participantStatus}
                          options={participantStatusList.map((item) => ({
                            value: item.status,
                            label: t(`statusList.${item.status}`, { ns: 'forms' }),
                          }))}
                        />
                        <ButtonRow
                          showBack={false}
                          forwardColor="primary"
                          justifyContent="center"
                          forwardLabel={t('participantLookup.button')}
                          buttonSize="large"
                          forwardIcon={<SearchIcon style={{ fontSize: 30 }} />}
                        />
                      </Box>
                    </form>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
            <>
              {listParticipantsByParticipantStatus && listParticipantsByParticipantStatus.participants && (
                <Box sx={{ mt: 4 }}>
                  <Paper elevation={2}>
                    <Box p={4}>
                      <Heading2>{t('participantLookup.resultsTitle')}</Heading2>
                      <DataGrid
                        apiRef={apiRef}
                        loading={false}
                        rows={
                          listParticipantsByParticipantStatus ? listParticipantsByParticipantStatus.participants : []
                        }
                        autoHeight
                        rowCount={rowCountState}
                        pageSizeOptions={[PAGE_SIZE]}
                        paginationModel={paginationModel}
                        paginationMode="server"
                        columns={columns}
                        hideFooterPagination
                        hideFooter
                        initialState={{
                          ...listParticipantsByParticipantStatus.participants,
                        }}
                        onRowClick={(params) => {
                          console.log('Row clicked:', params.row);
                          dispatch(getParticipantByHospitalNoThunk({ hospitalNo: params.row.hospitalNo }));
                          formik.setFieldValue('hospitalNo', params.row.hospitalNo.toString());
                        }}
                      />
                      <Typography paragraph variant="body1" align="center" mt={2} fontWeight={'600'}>
                        {t('participantLookup.totalResultsMessage', {
                          totalCount: listParticipantsByParticipantStatus.participants.length,
                        })}
                      </Typography>
                      {listParticipantsByParticipantStatus.paginationToken !== null && (
                        <Box display="flex" justifyContent="center" alignItems="center">
                          <Button size="medium" variant="contained" color="primary" onClick={handlePaginationChange}>
                            {t('participantLookup.loadMore')}
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Paper>
                </Box>
              )}
            </>
          </Box>
        </Paper>
      </InnerLayout>
      <Loader label={t('participantLookup.busy')} isVisible={isBusy} />
    </>
  );
}

export default ParticipantsLookupScene;
