import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { MdOutlineSave } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import Heading2 from '../../../../../../../../../../../components/text/Heading2';
import ROUTES, {
  UPDATE_STATUS_URL_PARAMS,
  URL_PARAMS,
} from '../../../../../../../../../../../core/constants/routes.constant';
import { useStyles } from './component.styles';
import { FormSelect } from '@omnigenbiodata/ui';
import { useAppDispatch, useAppSelector } from '../../../../../../../../../../../store';
import { participantStatusListSelector } from '../../../../../../../../../../../store/participant/selectors';
import { getParticipantStatusesThunk } from '../../../../../../../../../../../store/participant';
import { Grid, Typography } from '@mui/material';

export interface UpdateStatusBlockProps {
  hospitalNo: string;
  pStatus?: string | null;
}

function UpdateStatusBlock({ hospitalNo, pStatus }: UpdateStatusBlockProps) {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { t } = useTranslation('portal');
  const defaultParticipantStatus = 'ACTIVE';
  const participantStatusList =
    useAppSelector(participantStatusListSelector)?.filter((item) => item.available === true) || [];
  const [participantStatus, setParticipantStatus] = useState(pStatus || defaultParticipantStatus);

  useEffect(() => {
    console.log('pStatus--', pStatus);
    dispatch(getParticipantStatusesThunk({ status: pStatus || defaultParticipantStatus }));
  }, [dispatch, pStatus]);

  return (
    <>
      {participantStatusList && participantStatusList.length > 0 && (
        <Paper className={classes.paperDanger}>
          <Box p={4}>
            <Heading2 color="textPrimary" icon={MdOutlineSave}>
              {t('participantUpdateStatus.title')}
            </Heading2>

            <>
              <Grid container spacing={0}>
                <Grid item>
                  <Typography variant="body1" component="p">
                    {t('participantUpdateStatus.para1')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <FormSelect
                    placeholder=""
                    name="participantStatuses"
                    label={'Status'}
                    onChange={(e: any) => {
                      setParticipantStatus(e.currentTarget.value);
                    }}
                    onBlur={(e: any) => {
                      setParticipantStatus(e.currentTarget.value);
                    }}
                    value={participantStatus}
                    options={participantStatusList.map((item) => ({
                      value: item.status,
                      label: t(`statusList.${item.status}`, { ns: 'forms' }),
                    }))}
                  />
                </Grid>
              </Grid>

              <Button
                variant="contained"
                color="success"
                size="large"
                data-testid="withdraw"
                component={Link}
                to={
                  participantStatus === 'WITHDRAWN'
                    ? ROUTES.participantWithdrawal.replace(URL_PARAMS.hospitalNo, hospitalNo)
                    : ROUTES.updateParticipantStatus
                        .replace(URL_PARAMS.hospitalNo, hospitalNo)
                        .replace(UPDATE_STATUS_URL_PARAMS.participantStatus, participantStatus)
                }
              >
                {t('participantUpdateStatus.button')}
              </Button>
            </>
          </Box>
        </Paper>
      )}
    </>
  );
}

export default UpdateStatusBlock;
