import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import GroupIcon from '@mui/icons-material/Group';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Heading1 from '../../../../../../../../../../components/text/Heading1';
import ContentCard from '../../../../../../../../../../components/content/ContentCard';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonRow, FormTextarea, Loader } from '@omnigenbiodata/ui';
import InnerLayout from '../../../../../../../../../../layout/Inner';
import { useAppDispatch, useAppSelector } from '../../../../../../../../../../store';
import { HOSPTIAL_NUM_PREFIX } from '../../../../../../../../../../core/constants/forms.constant';
import {
  isWithdrawalBusySelector,
  participantProfileSelector,
  updateParticipantProfileThunk,
} from '../../../../../../../../../../store/participant';
import { ParticipantProfileInput } from '../../../../../../../../../../core/types/participants.types';
import ROUTES, { URL_PARAMS } from '../../../../../../../../../../core/constants/routes.constant';
import * as yup from 'yup';

function UpdateStatusConfirmScene() {
  const { t } = useTranslation('portal');
  const { hospitalNo, participantStatus } = useParams<any>();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(participantProfileSelector);
  const isBusy = useAppSelector(isWithdrawalBusySelector);
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    participantStatusChangeReason: yup.string().required(t('participantStatusChangeReason', { ns: 'validation' })),
  });

  const formik = useFormik({
    initialValues: {
      participantStatusChangeReason:
        profile && profile.participantStatusChangeReason ? profile.participantStatusChangeReason : '',
    },
    validationSchema,
    onSubmit: (data) => {
      if (profile) {
        const values: ParticipantProfileInput = {
          id: profile.id,
          firstName: profile.firstName ?? '',
          surname: profile.surname ?? '',
          address: profile.address ?? '',
          landline: profile.landline ?? '',
          postcode: profile.postcode ?? '',
          sex: profile.sex,
          dob: profile.dob ?? '',
          nationalID: profile.nationalID,
          participantStatus: participantStatus ?? null,
          participantStatusChangeReason: data.participantStatusChangeReason,
        };

        dispatch(updateParticipantProfileThunk(values));

        navigate(ROUTES.participant.replace(URL_PARAMS.hospitalNo, hospitalNo!));
      }
    },
  });

  return (
    <>
      <InnerLayout title="Site" title2="Portal">
        {!isBusy && profile && (
          <>
            <Heading1 icon={GroupIcon}>
              {t('participant.title')} &gt;{' '}
              <span style={{ fontSize: '0.7em' }}>
                {HOSPTIAL_NUM_PREFIX}
                {hospitalNo?.toString()}
              </span>{' '}
            </Heading1>
            <ContentCard>
              <form onSubmit={formik.handleSubmit}>
                <Box mb={8}>
                  <Typography variant="h5" component="h2" align="center" gutterBottom>
                    <strong>
                      Confirmation of Participant Status Update to &quot;
                      {t(`statusList.${participantStatus}`, { ns: 'forms' })}&quot;
                    </strong>
                  </Typography>
                  <Typography variant="body1" component="p" align="center" gutterBottom>
                    You have chosen to update the status for the following participant:
                  </Typography>
                  <Typography variant="h6" component="p" align="center" gutterBottom>
                    <strong>
                      {profile.firstName} {profile.surname}
                    </strong>
                  </Typography>
                  <FormTextarea
                    error={formik.errors.participantStatusChangeReason}
                    name={'participantStatusChangeReason'}
                    label={`${t('participantStatusChangeReason.label', { ns: 'forms' })}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    touched={formik.touched.participantStatusChangeReason}
                    value={formik.values.participantStatusChangeReason}
                    numberOfRows={3}
                  />
                </Box>

                <ButtonRow
                  backLabel={t('withdrawalConfirm.back')}
                  forwardLabel={`Confirm Update`}
                  forwardColor="error"
                  buttonSize="large"
                  isDisabled={isBusy}
                />
              </form>
            </ContentCard>
          </>
        )}
      </InnerLayout>
      <Loader label="Busy" isVisible={isBusy} />
    </>
  );
}

export default UpdateStatusConfirmScene;
