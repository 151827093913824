export const response = {
  data: {
    getParticipantByHospitalNo: {
      address: null,
      consent1: true,
      consent2: true,
      consent3: true,
      consent4: true,
      consent5: true,
      consent6: true,
      consent7: true,
      consent8: true,
      departmentOfEnrolment: 'Addington Oncology',
      dob: '1990-01-02',
      eligibility1: true,
      eligibility2: true,
      email: 'dmsa.enrolment.ac.00000000@ma3h93qg.mailosaur.net',
      enrolmentTime: '2022-03-21T15:42:58.591Z',
      enrolmentVideo: true,
      participantRef: 'DMSA00085999-QO',
      firstName: 'Celestine',
      health: true,
      healthDataOptIn: null,
      hospitalNo: '00000000',
      id: 'DMSAbfbb20cc27',
      landline: '+271000000005',
      languagePreference: 'en',
      mobile: '+277000000007',
      notifyNewProducts: null,
      notifyNewStudies: null,
      pis: true,
      plasmaCollectionTime: '2020-10-10T23:00:00.000Z',
      plasmaSampleID: '4476593',
      plasmaStatus: 'Sufficient Sample',
      postcode: '1623',
      privacyAccepted: null,
      serumCollectionTime: '2020-10-10T23:00:00.000Z',
      serumSampleID: '4476593',
      serumStatus: 'Sufficient Sample',
      sex: 'Female',
      staffMemberFirstName: 'Val',
      staffMemberSurname: 'Iduser',
      surname: 'Lowe-dmsa.enrolment.ac',
      termsAccepted: null,
      wholeBloodCollectionTime: '2020-10-10T23:00:00.000Z',
      wholeBloodSampleID: '4476593',
      wholeBloodStatus: 'Sufficient Sample',
      withdrawalOption: 'SharingOnly',
      withdrawalReason: 'test',
      witnessFirstName: null,
      witnessSurname: null,
      participantStatus: 'ACTIVE',
    },
  },
};

const resolver = (variables: any) => {
  if (variables.hospitalNum === '01000000') {
    return response;
  } else {
    return {
      data: {
        getParticipantByHospitalNo: {
          ...response.data.getParticipantByHospitalNo,
          plasmaCollectionTime: null,
          plasmaSampleID: null,
          plasmaStatus: null,
          serumCollectionTime: null,
          serumSampleID: null,
          serumStatus: null,
          wholeBloodCollectionTime: null,
          wholeBloodSampleID: null,
          wholeBloodStatus: null,
        },
      },
    };
  }
};

export default resolver;
