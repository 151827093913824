export const updateParticipantProfileMutation = `
mutation updateParticipantProfile($newProfile: ParticipantProfileInput!) {
    updateParticipantProfile(newProfile: $newProfile) {
        id
        hospitalNo
        participantRef
        email
        firstName
        surname
        dataHasArrived
        dob
        postcode
        mobile
        landline
        languagePreference
        sex
        address
        notifyNewProducts
        notifyNewStudies
        termsAccepted
        privacyAccepted
        healthDataOptIn
        departmentOfEnrolment
        eligibility1
        eligibility2
        enrolmentVideo
        populationGroup
        selfDefinedEthnicity
        pis
        consent1
        consent2
        consent3
        consent4
        consent5
        consent6
        consent7
        consent8
        health
        staffMemberFirstName
        staffMemberSurname
        witnessFirstName
        witnessSurname
        enrolmentTime
        serumStatus
        serumSampleID
        wholeBloodStatus
        wholeBloodSampleID
        plasmaStatus
        plasmaSampleID
        serumCollectionTime
        wholeBloodCollectionTime
        plasmaCollectionTime
        withdrawalOption
        withdrawalReason
        deceased
        dataHasArrived
        nationalID
        participantStatus
        participantStatusChangeReason
  }
}
`;

export const acceptStaffLegalNoticesMutation = `
mutation acceptStaffLegalNoticesMutation($termsAccepted: String!, $privacyAccepted: String!) {
    acceptStaffLegalNotices(termsAccepted: $termsAccepted, privacyAccepted: $privacyAccepted) {
          id
          email
          firstName
          surname
          mobile
          privacyAccepted
          termsAccepted
    }
}
`;

export const adminWithdrawParticipantMutation = `
mutation adminWithdrawParticipantMutation($participantId: ID!, $withdrawalOption: Withdrawal!, $withdrawalReason: String) {
    adminWithdrawParticipant(participantId: $participantId, withdrawalOption: $withdrawalOption,  withdrawalReason: $withdrawalReason) {
        id
        hospitalNo
        email
        firstName
        surname
        dob
        postcode
        mobile
        landline
        languagePreference
        sex
        address
        notifyNewProducts
        notifyNewStudies
        termsAccepted
        privacyAccepted
        healthDataOptIn
        departmentOfEnrolment
        eligibility1
        eligibility2
        enrolmentVideo
        pis
        consent1
        consent2
        consent3
        consent4
        consent5
        consent6
        consent7
        consent8
        health
        staffMemberFirstName
        staffMemberSurname
        witnessFirstName
        witnessSurname
        enrolmentTime
        serumStatus
        serumSampleID
        wholeBloodStatus
        wholeBloodSampleID
        plasmaStatus
        plasmaSampleID
        serumCollectionTime
        wholeBloodCollectionTime
        plasmaCollectionTime
        withdrawalOption
        withdrawalReason
    }
}
`;
