import { API as AMPLIFY_API } from 'aws-amplify';
import { API as MOCK_AMPLIFY_API } from '../mock/aws-amplify';
import {
  getListParticipantsByParticipantStatusQuery,
  getListParticipantStatusesQuery,
  getParticipantByHospitalNoQuery,
  getParticipantConsentFormLinkQuery,
} from '../../graphql/queries';
import { adminWithdrawParticipantMutation, updateParticipantProfileMutation } from '../../graphql/mutations';
import {
  ParticipantLookupResponse,
  ParticipantProfileInput,
  UpdateParticipantProfileResponse,
  WithdrawParticipantInput,
  WithdrawParticipantResponse,
  GetParticipantConsentFormLinkInput,
  GetListParticipantStatusesResponse,
} from '../../types/participants.types';

const API = process.env.REACT_APP_USE_MOCKS === 'true' ? MOCK_AMPLIFY_API : AMPLIFY_API;

const participantsAPI = {
  getParticipantByHospitalNo: async (values: any): Promise<ParticipantLookupResponse | any> => {
    return (await API.graphql({
      query: getParticipantByHospitalNoQuery,
      variables: {
        hospitalNo: values.hospitalNo,
      },
    })) as ParticipantLookupResponse;
  },
  updateParticipantProfile: async (
    values: ParticipantProfileInput,
  ): Promise<UpdateParticipantProfileResponse | any> => {
    return (await API.graphql({
      query: updateParticipantProfileMutation,
      variables: { newProfile: values },
    })) as UpdateParticipantProfileResponse;
  },

  adminWithdrawParticipant: async (values: WithdrawParticipantInput): Promise<WithdrawParticipantResponse | any> => {
    return (await API.graphql({
      query: adminWithdrawParticipantMutation,
      variables: values,
    })) as WithdrawParticipantResponse;
  },
  getParticipantConsentFormLink: async (
    values: GetParticipantConsentFormLinkInput,
  ): Promise<WithdrawParticipantResponse | any> => {
    return (await API.graphql({
      query: getParticipantConsentFormLinkQuery,
      variables: values,
    })) as WithdrawParticipantResponse;
  },
  getListParticipantStatuses: async (values: any): Promise<GetListParticipantStatusesResponse | any> => {
    return (await API.graphql({
      query: getListParticipantStatusesQuery,
      variables: values,
    })) as GetListParticipantStatusesResponse;
  },
  getListParticipantsByParticipantStatus: async (values: any): Promise<GetListParticipantStatusesResponse | any> => {
    return (await API.graphql({
      query: getListParticipantsByParticipantStatusQuery,
      variables: values,
    })) as GetListParticipantStatusesResponse;
  },
};

export default participantsAPI;
